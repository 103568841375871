<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseCaeSection-${section.id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ section.name }} <small class="badge bg-light-secondary ms-1" /></a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseCaeSection-${section.id}`"
      >
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr><!-- comentar con Begoña que campos exactamente ve la host y cuales ella -->
                <th>Title</th>
                <th>Section</th>
                <th>Subsection</th>
                <th>Year</th>
                <th>Renew</th>
                <th>Documents</th>
                <th>Need feedback</th>
                <th>Status</th>
                <th>Published</th>
                <th>Updated</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="hazard in section.hazards"
              >
                <tr
                  v-for="file in hazard.files"
                  :key="file.id"
                >
                  <td><strong>{{ file.title }}</strong></td>
                  <td>
                    <span>{{ hazard.title }}</span>
                  </td>
                  <td><span>OSH-XXX</span></td>
                  <td>{{ file.year }}</td>
                  <td>{{ file.renew_year }}</td>
                  <td>
                    1
                  </td>
                  <td>
                    <span
                      v-if="!file.need_feedback"
                      class="badge bg-light-danger"
                    >No</span>
                    <span
                      v-if="file.need_feedback"
                      class="badge bg-light-success"
                    >Yes</span>
                  </td>
                  <td>-</td>
                  <td>
                    <span
                      v-if="!file.published"
                      class="badge bg-light-danger"
                    >No</span>
                    <span
                      v-if="file.published"
                      class="badge bg-light-success"
                    >Yes</span>
                  </td>
                  <td><small>2023/02/14 · 09:17</small></td>
                  <td class="text-end">
                    <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                      <button
                        type="button"
                        class="btn btn-light-secondary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i data-feather="more-vertical" /></button>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a
                          href="#"
                          class="dropdown-item"
                        ><i
                          class="me-50"
                          data-feather="download"
                        /> Download</a>
                        <div class="dropdown-divider" />
                        <a
                          href="#"
                          class="dropdown-item text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#modalDeleteWithInput"
                        ><i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span></a>
                      </div>
                    </div>
                    <a
                      href=""
                      class="btn btn-icon btn-light-secondary d-block d-sm-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasMenu"
                      aria-controls="offcanvasBottom"
                    ><i data-feather="more-vertical" /></a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    section: { type: Object, required: true },
  },
  data() {
    return {
      sections: [],
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      file: 'cae/file',
      filters: 'filters/filters',
      fileIcreas: 'cae/fileIcreas',
    }),
  },
  mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
}
</script>
